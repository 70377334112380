"use client";
import {
  ComponentType,
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { AuthenticationContext } from "@atlas-ui/utils";
import { UserProfile, useUser } from "@auth0/nextjs-auth0/client";

import axios from "axios";
import { usePathname, useRouter } from "next/navigation";

export interface WithAuthenticationProps {
  user?: UserProfile;
}

interface WithAuthenticationOptions {
  onRedirecting?: FC;
}

const VERIFY_ACCOUNT_PATH = "/auth/verify-account";
const ONBOARDING_PATH = "/onboarding";

const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds

const fetchUserDataWithCache = async (endpoint: string) => {
  const currentTime = Date.now();
  const cachedData = sessionStorage.getItem(endpoint);

  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);

    if (currentTime - timestamp < TEN_MINUTES) {
      // If the cached data is less than 10 minutes old, return it
      return { data, isCached: true };
    }
  }

  // If there's no cached data or it's more than 10 minutes old, fetch new data
  const response = await axios.get(endpoint);
  const data = response.data;

  // Cache the new data along with the current timestamp
  sessionStorage.setItem(
    endpoint,
    JSON.stringify({ data, timestamp: currentTime })
  );

  return { data, isCached: false };
};

const useConstantsAndState = () => {
  const router = useRouter();
  const { user, isLoading: isLoadingAuth0User, checkSession } = useUser();
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const pathName = usePathname();

  useEffect(() => {
    const updateUser = async () => {
      const { isCached } = await fetchUserDataWithCache(
        "/api/auth/update-session"
      );
      if (!isCached) {
        await checkSession();
      }
    };

    updateUser().then(() => {
      setIsUserUpdated(true);
    });
  }, [checkSession]);

  return {
    router,
    user,
    isLoadingAuth0User: !isUserUpdated || isLoadingAuth0User,
    pathName,
  };
};

const useUserAuthenticationAndVerification = (
  isLoadingAuth0User: boolean,
  user: UserProfile | undefined,
  pathName: string,
  router: ReturnType<typeof useRouter>
) => {
  const [isLoading, setIsLoading] = useState(true);
  useLayoutEffect(() => {
    if (isLoadingAuth0User) {
      return;
    }

    if (!user) {
      router.replace("/api/auth/login?returnTo=/");
      return;
    }

    if (
      user &&
      !user?.email_verified &&
      !pathName?.startsWith(VERIFY_ACCOUNT_PATH)
    ) {
      router.replace("/auth/verify-account");
      return;
    }

    setIsLoading(false);
  }, [isLoadingAuth0User, pathName, router, user]);

  return { isLoading };
};

const useUserOnboarding = (
  pathName: string,
  router: ReturnType<typeof useRouter>,
  user: UserProfile | undefined
) => {
  const [isLoading, setIsLoading] = useState(true);
  const { user: docsnapUser } = useContext(AuthenticationContext);

  useEffect(() => {
    if (!docsnapUser) return;

    if (
      !docsnapUser.onboardingComplete &&
      !pathName?.startsWith(ONBOARDING_PATH) &&
      user?.email_verified
    ) {
      router.push(ONBOARDING_PATH);
      return;
    }

    setIsLoading(false);
  }, [pathName, router, docsnapUser, user?.email_verified]);

  return { isLoading };
};

interface RenderComponentProps<P extends object> {
  isLoading: boolean;
  user: UserProfile | undefined;
  pathName: string;
  onRedirecting: FC | undefined;
  props: P;
  Component: ComponentType<P & WithAuthenticationProps>;
}

const renderComponent = <P extends object>({
  isLoading,
  user,
  pathName,
  onRedirecting,
  props,
  Component,
}: RenderComponentProps<P>) => {
  if (
    isLoading ||
    (!isLoading &&
      !user?.email_verified &&
      !pathName?.startsWith(VERIFY_ACCOUNT_PATH))
  ) {
    return onRedirecting ? onRedirecting({}) : null;
  }

  return <Component {...props} user={user} />;
};

export const withAuthentication = <P extends object>(
  Component: ComponentType<P & WithAuthenticationProps>,
  options: WithAuthenticationOptions = {}
): FC<P> => {
  const { onRedirecting } = options ?? {};

  return (props) => {
    const { router, user, isLoadingAuth0User, pathName } =
      useConstantsAndState();

    const { isLoading: isLoadingVerification } =
      useUserAuthenticationAndVerification(
        isLoadingAuth0User,
        user,
        pathName,
        router
      );

    const { isLoading: isLoadingOnboarding } = useUserOnboarding(
      pathName,
      router,
      user
    );

    return renderComponent({
      isLoading:
        isLoadingAuth0User || isLoadingVerification || isLoadingOnboarding,
      user: user,
      pathName: pathName,
      onRedirecting: onRedirecting,
      props: props,
      Component: Component,
    });
  };
};
